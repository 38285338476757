
































































































































































































































































































import Breadcrumb from '@/components/Breadcrumb.vue';
import Component, {mixins} from 'vue-class-component';
import {Prop, Vue, Watch} from 'vue-property-decorator';
import _ from 'lodash';
import AXIOS from '@/services/axios-wrapper';
import moment from 'moment';

interface YourComponentType extends Vue {
  clear(): any;
}

import {
  HrDbUserDto,
  HrDbUserInitPWDto,
  HrRequestSummary,
  HrRequestDetail,
  HrRequestExtraInfo,
  AttachFileType,
  HrRequestAttachFilesResponse,
  HrRequestFormUploadFiles,
  HrRequestJikmuOptionItem,
} from '@/model-hr';
import GeneralUtil from '@/util/GeneralUtil';
import {BV_OptionItem, FormUploadFiles} from '@/models';
import {BizSirenResultDto} from '@/services/LoginModels';
import {loginService} from '@/services/login.service';
import FormAttachHrFiles from '@/components/FormAttachHrFiles.vue';
import HrRequestViewSummary from '@/components/HrRequestViewSummary.vue';
import {BreadcrumbInfo, HrBoardArticle} from '@/model-hr';
import hrBoardService from '@/services/hr.service';
import CONST from '@/util/Const';

@Component({
  components: {
    Breadcrumb,
    GeneralUtil,
    FormAttachHrFiles,
    HrRequestViewSummary,
  },
})
export default class HrRequest extends mixins(GeneralUtil) {
  hrRequestId = this.$route.params.hrRequestId as unknown as number;
  dtoHrBoard: HrBoardArticle | null = null;

  introduce_breadcrumb: BreadcrumbInfo = {
    toplink: 'HOME',
    prevlink: '인재채용',
    present: '채용공고결과',
  };
  v_datepicker_masks = {
    input: 'YYYY-MM-DD',
  };
  workingEnableDay: Date = moment().toDate();
  private text1 =
    'SK PINX 개인정보처리방침\n \nSK PINX(thepinx.co.kr, 이하 PINX)이(가) 취급하는 모든 개인정보는 개인정보보호법 등 관련 법령상의 개인정보보호 규정을 준수하여 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.\n';
  private text2 =
    '1. 개인정보 수집 및 이용주체\n \n本 채용홈페이지를 통해 제출하는 지원서는 귀하께서 지원서를 제출하는 회사(이하 채용회사)가 직접 접수하고 관리하며, 향후 지원서 관리책임은 채용회사에 있습니다.\n\n 2. 동의를 거부할 권리 및 동의 거부에 따른 불이익\n' +
    '지원자는 아래 개인정보 제공 등에 관해 동의하지 않을 권리가 있습니다.\n' +
    '다만, 지원서를 통해 제공받는 정보는 회사의 채용 전형에 필수적인 항목으로 해당 정보를 제공받지 못할 경우 회사는 공정한 선발전형을 진행할 수 없습니다.\n' +
    '따라서 개인정보 제공에 대해 동의하지 않는 경우 채용 전형에 지원이 제한될 수 있습니다.\n' +
    '지원자의 동의 거부 권리 및 동의 거부에 따른 불이익은 아래에 제시되는 모든 동의사항에 해당됩니다.';

  hrUserDto: HrDbUserDto = {
    kor_nm: '',
    biryymm_dt: '',
    email_nm: '',
    sex_cd: '',
    scret_no: '',
    hp_no: '',
  };
  hrDetailDto: HrRequestDetail = {
    koNm: '',
    biryymmDt: '',
    emailNm: '',
    sexCd: '',
    scretNo: '',
    hpNo: '',
    engLnmNm: '',
    ntnlNm: '',
    fgNm: '',
    telNo: '',
    addrNm: '',
    dutyDcDepart1: '',
    dutyDc1: '',
    dutyDcDepart2: '',
    dutyDc2: '',
    requestPath: '',
    workingStatus: '',
    workingEnableDay: '',
    workingWantPay: '',
    prGrowth: '',
    prStrenghtWeak: '',
    prMotivation: '',
    prDream: '',
    extraInfos: [],
    attachFiles: [],
    isCompleted: false,
    isTempPw: false,
  };
  jikmuOptionItems: HrRequestJikmuOptionItem[] = [
    {
      depart: '경영지원팀',
      options: [
        {value: 'HR', text: 'HR'},
        {value: '재무회계', text: '재무회계'},
        {value: '경리출납', text: '경리출납'},
        {value: '총무', text: '총무'},
        {value: '구매', text: '구매'},
        {value: '운전', text: '운전'},
        {value: '기획', text: '기획'},
        {value: '홍보', text: '홍보'},
        {value: '기숙사관리', text: '기숙사관리'},
      ],
    },
    {
      depart: '식음서비스팀',
      options: [{value: '서버', text: '서버'}],
    },
    {
      depart: '조리팀',
      options: [
        {value: '조리사', text: '조리사'},
        {value: '찬모', text: '찬모'},
        {value: '찬모보조', text: '찬모보조'},
        {value: '주방보조', text: '주방보조'},
        {value: '파티셰', text: '파티셰'},
        {value: '펜츄리', text: '펜츄리'},
      ],
    },
    {
      depart: '객실팀',
      options: [
        {value: '프런트', text: '프런트'},
        {value: '컨시어지', text: '컨시어지'},
        {value: '객실관리', text: '객실관리'},
        {value: '컨시어지매니저', text: '컨시어지매니저'},
        {value: '안전요원', text: '안전요원'},
        {value: '프로샵', text: '프로샵'},
      ],
    },
    {
      depart: '경기운영팀',
      options: [
        {value: '회원관리', text: '회원관리'},
        {value: '카트관리', text: '카트관리'},
        {value: '경기진행', text: '경기진행'},
      ],
    },
    {
      depart: '시설관리팀',
      options: [
        {value: '행정사무', text: '행정사무'},
        {value: '기계관리', text: '기계관리'},
        {value: '시설관리', text: '시설관리'},
        {value: '전기관리', text: '전기관리'},
      ],
    },
    {
      depart: '마케팅팀',
      options: [
        {value: 'RM', text: 'RM'},
        {value: '세일즈', text: '세일즈'},
        {value: '마케팅', text: '마케팅'},
      ],
    },
    {
      depart: '코스TF',
      options: [
        {value: '조경관리', text: '조경관리'},
        {value: '영선관리', text: '영선관리'},
        {value: '코스관리', text: '코스관리'},
      ],
    },
    {
      depart: 'ESGTF',
      options: [{value: 'ESG기획', text: 'ESG기획'}],
    },
    {
      depart: 'DTTF',
      options: [{value: 'IT', text: 'IT'}],
    },
  ];
  userBirthDay: Date = moment().toDate();
  //1로그인 정보입력, 2신규사용자 인증 입력, 4. 상세정보입력페이지
  pageState = 1;
  tabIndex = 0;
  chkUpload = false;
  chkCollectInfo = false;
  todayDate = moment().format('YYYY년 MM월 DD일');

  private identityAuth = false;
  private host = window.location.protocol + '//' + window.location.host;
  private reqInfo = '';
  private windowRef: any = null;
  private repeatPassword = '';
  private duty_dc1 = '';
  private duty_dc2 = '';

  //증명사진
  image = null;
  idPhoto: string | null = null;

  getDeaprtOptions(): BV_OptionItem[] {
    if (!this.dtoHrBoard?.hrRecruitInfoList) {
      const emptyOptions: BV_OptionItem[] = [];
      return emptyOptions;
    } else {
      //모집부서 가져오기
      const returnOptions: BV_OptionItem[] = [];
      for (const item of this.dtoHrBoard.hrRecruitInfoList) {
        if (returnOptions.filter(x => x.value === item.mogip1).length === 0) {
          const addItem: BV_OptionItem = {
            text: item.mogip1,
            value: item.mogip1,
          };
          returnOptions.push(addItem);
        }
      }

      return returnOptions;
    }
  }

  getDutyOptions(depart: string): BV_OptionItem[] {
    if (!this.dtoHrBoard?.hrRecruitInfoList || !depart) {
      const emptyOptions: BV_OptionItem[] = [];
      return emptyOptions;
    } else {
      //모집부서 가져오기
      const returnOptions: BV_OptionItem[] = [];
      for (const item of this.dtoHrBoard.hrRecruitInfoList.filter(y => y.mogip1 === depart)) {
        if (returnOptions.filter(x => x.value === item.mogip2).length === 0) {
          const addItem: BV_OptionItem = {
            text: item.mogip2,
            value: item.mogip2,
          };
          returnOptions.push(addItem);
        }
      }

      return returnOptions;
    }
  }

  mounted(): void {
    this.fetchData();
  }

  async fetchData() {
    try {
      this.showLoadingModal();
      const response = await hrBoardService.getArticleDetails(this.hrRequestId, 'HrBoardArticleSummary');
      this.dtoHrBoard = response;
    } catch (error) {
      console.error(error);
    } finally {
      this.hideLoadingModal();
    }
  }

  get hasImage() {
    return !!this.image;
  }

  get hasImageSrc() {
    return !!this.idPhoto;
  }

  get isPass() {
    if (
      this.hrDetailDto.requestStatus === '서류전형합격' ||
      this.hrDetailDto.requestStatus === '필기전형합격' ||
      this.hrDetailDto.requestStatus === '1차면접합격' ||
      this.hrDetailDto.requestStatus === '2차면접합격' ||
      this.hrDetailDto.requestStatus === '최종합격'
    ) {
      return true;
    } else {
      return false;
    }
  }

  get hrStep() {
    if (this.hrDetailDto.requestStatus === '서류전형합격') {
      return '서류전형';
    } else if (this.hrDetailDto.requestStatus === '필기전형합격') {
      return '필기전형';
    } else if (this.hrDetailDto.requestStatus === '1차면접합격') {
      return '1차면접전형';
    } else if (this.hrDetailDto.requestStatus === '2차면접합격') {
      return '2차면접전형';
    } else if (this.hrDetailDto.requestStatus === '최종합격') {
      return '최종합격';
    } else return '';
  }

  base64Encode(data: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  @Watch('image', {immediate: true, deep: true})
  public imageChanged(newValue: string, oldValue: string) {
    if (newValue !== oldValue) {
      if (newValue) {
        this.base64Encode(newValue)
          .then(value => {
            this.idPhoto = value as any;
            //this.uploadImage();
          })
          .catch(() => {
            this.idPhoto = null;
          });
      } else {
        this.idPhoto = null;
      }
    }
  }

  changePageState(pageNumber: number) {
    this.pageState = pageNumber;
  }

  private accessToken = '';

  async checkDuplicate(search: any): Promise<boolean> {
    const searchQuery = this.build_search(search, null);
    const responseDuplicate = await hrBoardService.getHrRequestSearch(searchQuery);
    if (responseDuplicate._embedded.hrRequests.length > 0) {
      return true;
    } else return false;
  }

  async checkHrUser() {
    this.showLoadingModal();
    const postData = {
      koNm: this.hrUserDto.kor_nm,
      emailNm: this.hrUserDto.email_nm,
      biryymmDt: this.hrUserDto.biryymm_dt,
      hpNo: this.hrUserDto.hp_no,
      hrBoardInfoId: this.hrRequestId,
      scretNo: this.hrUserDto.scret_no,
    };

    const apiPath = `/hrrequest/checkHrUser`;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    //토큰 저장
    await AXIOS.post(apiPath, postData)
      .then(async response => {
        this.accessToken = response.data.accessToken;
        await this.fetchHrRequestData(true);
      })
      .catch(async function (error) {
        console.error();
        if (error.response) {
          if (error.response.status === 404) {
            self.$bvModal.msgBoxOk(self.htmlToVNode('입사 지원 이력이 없습니다.<br>입력 정보를 확인해주세요?'), {
              title: '알림',
              centered: true,
            });
            return;
          } else if (error.response.status === 409) {
            //비밀번호 틀림
            self.$bvModal.msgBoxOk('입력된 정보를 확인해주세요', {
              title: '알림',
              centered: true,
            });
            return;
          }
          self.$bvModal.msgBoxOk('지원자 조회중 오류발생', {
            title: '지원자 조회중 오류발생',
            centered: true,
          });
        } else if (error.request) {
          self.$bvModal.msgBoxOk('지원자 조회중 오류발생', {
            title: '지원자 조회중 오류발생',
            centered: true,
          });
          console.log(error.request);
        } else {
          // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
          self.$bvModal.msgBoxOk('지원자 조회중 오류발생', {
            title: '지원자 조회중 오류발생',
            centered: true,
          });
          //console.log('Error', error.message);
        }
        //console.log(error.config);
      })
      .finally(() => {
        self.hideLoadingModal();
      });
  }

  async fetchHrRequestData(showViewPage: boolean) {
    this.showLoadingModal();
    const postData = {
      accessToken: this.accessToken,
      tokenType: 'bearer',
    };

    const apiPath = `/hrrequest/getHrRequest`;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    //토큰 저장
    await AXIOS.post(apiPath, postData)
      .then(response => {
        //console.log(response);
        const newHrRequest = response.data;
        if (!newHrRequest.prGrowth) newHrRequest.prGrowth = '';
        if (!newHrRequest.prStrenghtWeak) newHrRequest.prStrenghtWeak = '';
        if (!newHrRequest.prMotivation) newHrRequest.prMotivation = '';
        if (!newHrRequest.prDream) newHrRequest.prDream = '';
        this.hrDetailDto = newHrRequest;
        this.setLocalVariable();
        if (newHrRequest.idPhoto) this.idPhoto = newHrRequest.idPhoto;
        if (showViewPage) this.pageState = 11;
      })
      .catch(async function (error) {
        console.log(error);
        if (error.response) {
          self.$bvModal.msgBoxOk('지원자 조회중 오류발생', {
            title: '지원자 조회중 오류발생',
            centered: true,
          });
        } else if (error.request) {
          self.$bvModal.msgBoxOk('지원자 조회중 오류발생', {
            title: '지원자 조회중 오류발생',
            centered: true,
          });
          console.log(error.request);
        } else {
          // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
          self.$bvModal.msgBoxOk('지원자 조회중 오류발생', {
            title: '지원자 조회중 오류발생',
            centered: true,
          });
          //console.log('Error', error.message);
        }
        //console.log(error.config);
      })
      .finally(() => {
        self.hideLoadingModal();
      });
  }

  setLocalVariable() {
    if (this.hrDetailDto.workingEnableDay)
      this.workingEnableDay = moment(this.hrDetailDto.workingEnableDay, CONST.DATEONLY_FORMAT).toDate();
    else this.workingEnableDay = moment().toDate();
  }

  getLocalVariable() {
    this.hrDetailDto.workingEnableDay = moment(this.workingEnableDay).format(CONST.DATEONLY_FORMAT);
  }
}
